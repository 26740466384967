.flex {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.flexGeo {
  display: flex;
  flex-direction: column;
  padding-top: 15px;
}
