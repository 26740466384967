.footer {
  border-top: solid 1px rgb(225, 225, 225);
  width: 100%;
  height: 50px;
  margin-top: auto;
  bottom: 0;
  left: 0;
  flex: 0 0 50px;
}

.Logo {
  width: 75px;
  float: left;
  padding: 10px 10px 10px 5px;
}

.footerText {
  font-weight: bold;
  display: inline;
  float: right;
  margin: auto;
  padding: 0 10px 5px 0;
}
