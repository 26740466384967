.subCard {
  width: 60%;
  margin: 2em auto 1em auto;
}

.subHeader {
  text-align: center;
  padding: 0.25em 0;
}

.noSubs {
  text-align: center;
}
