.header {
  border-bottom: solid 1px rgb(225, 225, 225);
  box-sizing: border-box;
  text-align: center;
  display: flex;
  position: relative;
  justify-content: space-between;
}

.headerText {
  font-weight: bold !important;
  display: inline;
  vertical-align: middle;
  color: rgb(34, 31, 32);
}

.headerButton {
  height: 100%;
}

.headerLogoContainer {
  height: 60px;
  padding-bottom: 1em;
  vertical-align: middle;
  pointer-events: all;
}

.headerLogoImage {
  padding-top: 5px;
  padding-left: 0.5em;
  padding-bottom: 5px;
  cursor: pointer;
}
